import {useState, useCallback, useMemo} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import {ProductListing} from '../../../../Screens/CategoryPage/ProductListing'
import Tabs from './Components/TabsV2'
import Responsive from '../../../HOC/Responsive'
import ProductsDesktop from '../../CategoryPage/Products/ProductsDesktop'
import ProductsMobile from '../../CategoryPage/Products/ProductsMobile'
import {StyledWrapper, TitleAndSubtitleContainer} from './styles'
import {StyledWrapper as StyledProductsWrapper} from '../../CategoryPage/Products/styles'
import {findProductsViaCategoryName} from './ProductFinder.utils'
import {withCustomErrorBoundary} from '../../../../utils/withCustomErrorBoundary'
import Typography from '../../../common/Typography/Typography'
import useATCBottomSheetParam from 'src/hooks/product/ATCBottomSheet/useATCBottomSheet'

const ProductFinder = (props) => {
  const {isMobile = false, rclTheme = false} = props

  const {
    headingLevel = 2,
    mainTitle = 'Best Sellers',
    subtitle = 'Browse our best sellers by type of your concerns',
    categories = [],
    sectionName = 'productFinder',
    themeToUse,
    isTitleDangerouslySet = false,
    cta,
    useATCBottomSheet = false,
  } = props.state || {}

  const {label = ''} = cta || {}

  const {
    onAddToCard = noop,
    onNavigation = noop,
    onProductClick = noop,
    handleCta = noop,
  } = props.actions || {}

  const [activeCategory, setActiveCategory] = useState(
    () => categories[0]?.name
  )

  const [{}, {openBottomSheetThroughFetching}] = useATCBottomSheetParam()

  const handleActiveCategoryChange = useCallback(
    (newCategory) => {
      setActiveCategory(newCategory)
    },
    [setActiveCategory]
  )

  const activeProducts = useMemo(
    () => findProductsViaCategoryName(categories, activeCategory),
    [activeCategory, categories]
  )

  const handleViewAllClick = useCallback(() => {
    handleCta(cta)
  }, [handleCta, cta])

  const handleAddToCart = useCallback(
    async (data) => {
      onAddToCard(data, 'Home Page')
      if (useATCBottomSheet) {
        const {urlKey = ''} = data || {}
        openBottomSheetThroughFetching({
          urlKey,
          image: data?.image?.[0],
          source: 'Home ATC Bottom Sheet',
          atcBottomSheetSourcePage: 'Home ATC',
          baseProductUrlKey: data?.urlKey,
        })
      }
    },
    [openBottomSheetThroughFetching]
  )

  // return null if tabs are empty or products are empty or length are not equal
  if (isEmpty(categories)) {
    return null
  }

  const isTabValid = activeCategory !== null

  return (
    <StyledWrapper
      id="productFinder"
      data-category={themeToUse || activeCategory}
      data-section={sectionName}
    >
      <TitleAndSubtitleContainer>
        <div
          className={`title main-title ${
            isTitleDangerouslySet ? 'remove-font-weight' : ''
          }`}
        >
          <Typography
            text={mainTitle}
            variant="heading-sm-bold"
            headingLevel={headingLevel}
            isDangerouslySet={isTitleDangerouslySet}
          />
        </div>
        <Typography
          text={subtitle}
          variant="body-base-regular"
          customClassName="title subtitle"
        />
      </TitleAndSubtitleContainer>

      <Tabs
        items={categories}
        activeCategory={activeCategory}
        onTabChange={handleActiveCategoryChange}
        themeToUse={themeToUse}
      />

      {isTabValid && (
        <StyledProductsWrapper className="products-wrapper">
          {rclTheme && (
            <>
              <ProductListing
                state={{products: activeProducts, sectionName}}
                actions={{
                  onAddToCart: handleAddToCart,
                  onProductClick: onProductClick,
                }}
              />
            </>
          )}
          {!rclTheme && (
            <>
              {isMobile ? (
                <ProductsMobile
                  section={{products: activeProducts}}
                  isMobile={isMobile}
                  onAddToCart={handleAddToCart}
                  onProductClick={onNavigation}
                  sectionName={sectionName}
                />
              ) : (
                <ProductsDesktop
                  section={{products: activeProducts}}
                  isMobile={isMobile}
                  onAddToCart={handleAddToCart}
                  onProductClick={onNavigation}
                  sectionName={sectionName}
                />
              )}
            </>
          )}
        </StyledProductsWrapper>
      )}
      {!!label && (
        <div className="cta-btn-container">
          <button className="cta-btn" onClick={handleViewAllClick}>
            <label className="cta-label">{label}</label>
            <FontAwesomeIcon
              icon={faChevronRight}
              className="font-awesome-icon"
            />
          </button>
        </div>
      )}
    </StyledWrapper>
  )
}

export default withCustomErrorBoundary(Responsive(ProductFinder))
