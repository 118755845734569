import {LargeRoundedBtnCTA} from '../../../../../common/Button'
import {StyledWrapper} from './styles'
import PropTypes from 'prop-types'
import leaf from '../../assets/leaf.svg'
import man from '../../assets/man.svg'
import {useInternationalizationContext} from '../../../../../Context/InternationalizationContext'

const ProductDetail = (props) => {
  const {activeProduct = {}, buttonText = 'Add to Cart'} = props.state || {}
  const {onAddToCard = () => {}} = props.actions || {}

  const {currency} = useInternationalizationContext()

  const {
    productId = '',
    title = '',
    image = '',
    actualPrice = '',
    discountedPrice = '',
    keyPoints = [],
    ingredients = [],
    sku = '',
    link = '#',
  } = activeProduct || {}

  const handleAddToCart = () => {
    onAddToCard(activeProduct)
  }

  const transformedDiscountedPrice = discountedPrice || actualPrice
  const isShowActualPrice = transformedDiscountedPrice < actualPrice

  return (
    <StyledWrapper>
      <div className="product_finder_key_points_container">
        <div className="product_finder_key_points_title">{title}</div>
        <div className="product_finder_key_points_list">
          {keyPoints.map((point, index) => {
            const {title = '', icon = ''} = point || {}
            return (
              <div className="product_finder_key_points_list_item" key={index}>
                <img src={icon} alt={title} />
                <span>{title}</span>
              </div>
            )
          })}
        </div>
        <a href={link}>
          <img
            src={image}
            alt={title}
            className="product_finder_key_points_image"
          />
        </a>
      </div>
      <div className="product_finder_ingredients_container">
        <img
          src={leaf}
          alt="leaf"
          className="product_finder_ingredients_leaf"
        />
        <img src={man} alt="man" className="product_finder_ingredients_man" />
        <div className="product_finder_ingredients_list">
          {ingredients.map((ingredient, index) => {
            const {key = '', value = '', color = '#588ECE'} = ingredient || {}
            return (
              <div
                style={{color}}
                className="product_finder_ingredients_list_item"
                key={index + key}
              >
                <span>{value}</span>
                <span>{key}</span>
              </div>
            )
          })}
        </div>
        <div className="product_finder_ingredients_price_container">
          <span className="product_finder_ingredients_price_rupee">
            {currency}
          </span>
          <span className="product_finder_ingredients_price_discounted">
            {transformedDiscountedPrice}
          </span>
          {isShowActualPrice && (
            <span className="product_finder_ingredients_price_actual">
              {currency}
              {actualPrice}
            </span>
          )}
        </div>
        <LargeRoundedBtnCTA
          text={buttonText}
          onClick={handleAddToCart}
          className="product_finder_ingredients_button"
        />
      </div>
    </StyledWrapper>
  )
}

ProductDetail.propTypes = {
  state: PropTypes.shape({
    activeProduct: PropTypes.shape({
      productId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      actualPrice: PropTypes.string.isRequired,
      discountedPrice: PropTypes.string.isRequired,
      keyPoints: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
        })
      ).isRequired,
      ingredients: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    buttonText: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    onAddToCard: PropTypes.func.isRequired,
  }).isRequired,
}

export default ProductDetail
