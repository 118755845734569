import {StyledWrapper} from './styles'
import {useEffect, useState} from 'react'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'

const Tabs = (props) => {
  const {tabs = [], onTabChange = () => {}} = props
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    if (!isEmpty(tabs)) {
      setActiveTab(tabs[0].tabId)
    }
  }, [tabs])

  if (isEmpty(tabs)) {
    return null
  }

  const handleTabClick = (tabId) => {
    setActiveTab(tabId)
    onTabChange(tabId)
  }
  return (
    <StyledWrapper>
      <div className="tab_container">
        {tabs.map((tab, index) => {
          const {tabId = '', title = ''} = tab
          const isActive = tabId === activeTab
          const className = `product_finder_tabs_tab ${
            isActive ? 'active' : ''
          }`

          let onClick = () => {}

          // prevent un-necessary clicks
          if (!isActive) {
            onClick = () => handleTabClick(tabId)
          }
          return (
            <div onClick={onClick} className={className} key={tabId}>
              <span>{title}</span>
            </div>
          )
        })}
      </div>
    </StyledWrapper>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  onTabChange: PropTypes.func.isRequired,
}

export default Tabs
