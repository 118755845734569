import {StyledCTA, StyledDirect} from './styles'
import PropTypes from 'prop-types'

//Large Rounded button for mobile and
// Extra large rounded button for desktop
const LargeRoundedBtn = (props) => {
  const {
    text = 'button',
    direct = true,
    link = '',
    onClick = () => {},
    className = '',
    ...rest
  } = props

  if (direct) {
    return (
      <StyledDirect {...rest} href={link} className={className}>
        {text}
      </StyledDirect>
    )
  }
  return (
    <StyledCTA {...rest} onClick={onClick} className={className}>
      {text}
    </StyledCTA>
  )
}

LargeRoundedBtn.propTypes = {
  text: PropTypes.string.isRequired,
  direct: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default LargeRoundedBtn
