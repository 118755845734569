import styled from 'styled-components'

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .tab_container {
    max-width: 1120px;
    margin: auto;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .tab_container::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

  .product_finder_tabs_tab {
    margin-left: min(
      20px,
      calc(10px + (20 - 10) * ((100vw - 360px) / (1440 - 360)))
    );
    padding: 17px 20px;
    border-radius: 20px;
    border: 1px solid white;

    outline: none;
    font-size: 16px;
    font-weight: var(--brand-font-weight-400);
    font-family: var(--brand-font-family-title);
    white-space: nowrap;
    transition: all 0.5s ease;
    cursor: pointer;
    outline: none;
  }
  .product_finder_tabs_tab.active {
    background-color: var(--brand-primary-white-color);
  }

  .product_finder_tabs_tab:first-child {
    margin-left: 12px;
  }

  .product_finder_tabs_tab:last-child {
    margin-right: 12px;
  }

  @media (min-width: 768px) {
    .product_finder_tabs_tab {
      flex-shrink: 0;
      padding: 28px 9px;
      min-width: 240px;
      text-align: center;
      font-size: 20px;
    }
  }
`
