import styled from 'styled-components'

export const StyledWrapper = styled.div`
  position: relative;

  // key points start
  .product_finder_key_points_container {
    margin-top: 50px;
    margin-right: 100px;
    margin-left: 20px;
  }

  .product_finder_key_points_title {
    font-size: min(
      60px,
      calc(32px + (60 - 32) * ((100vw - 360px) / (1440 - 360)))
    );
    font-family: var(--brand-font-family-title);
    font-weight: var(--brand-font-weight-400);
    text-transform: capitalize;
    color: var(--category-primary-color);
  }

  .product_finder_key_points_list {
    margin-top: 6px;
    width: 100%;
  }
  .product_finder_key_points_list_item {
    margin-top: 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: var(--brand-font-family-text);
    font-weight: var(--brand-font-weight-medium);
  }
  .product_finder_key_points_list_item img {
    width: 30px;
    margin-right: 10px;
  }

  .product_finder_key_points_image {
    position: absolute;
    top: 12%;
    right: 10px;
    width: 125px;
  }

  // ingredients start
  .product_finder_ingredients_container {
    margin: 50px 8px 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .product_finder_ingredients_list {
    background-color: var(--brand-primary-white-color);
    border-radius: 30px;
    padding: 10px;
    display: flex;
    width: 100%;
    z-index: 1;
  }
  .product_finder_ingredients_list_item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-right: 1px solid var(--brand-primary-black-color);
    font-family: var(--brand-font-family-title);
    font-weight: var(--brand-font-weight-400);
    font-size: 30px;
    padding: 6px;
  }
  .product_finder_ingredients_list_item:last-child {
    border: none;
  }

  .product_finder_ingredients_list_item span:last-child {
    font-size: 14px;
    margin-top: 4px;
  }

  .product_finder_ingredients_price_container {
    margin-top: 30px;
    margin-bottom: 12px;
    font-family: var(--brand-font-family-text);
    font-weight: var(--brand-font-weight-700);
    align-items: flex-start;
    display: flex;
  }

  .product_finder_ingredients_price_rupee {
    font-size: 20px;
    margin-right: 5px;
  }
  .product_finder_ingredients_price_discounted {
    font-size: 40px;
  }

  .product_finder_ingredients_price_actual {
    align-self: flex-end;
    font-size: 20px;
    text-decoration: line-through;
    color: var(--brand-primary-grey-color);
    font-weight: var(--brand-font-weight-400);
    margin-left: 6px;
    margin-bottom: 5px;
  }

  .product_finder_ingredients_leaf {
    display: none;
  }
  .product_finder_ingredients_man {
    display: none;
  }

  @media (min-width: 768px) {
    margin: auto;
    max-width: 1120px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .product_finder_key_points_container,
    .product_finder_ingredients_container {
      margin-top: 100px;
      max-width: 300px;
    }
    .product_finder_ingredients_container {
      position: relative;
    }

    .product_finder_key_points_list {
      margin-top: 40px;
    }

    .product_finder_key_points_list_item {
      margin-top: 30px;
      font-size: 20px;
    }
    .product_finder_key_points_list_item img {
      width: 50px;
      margin-right: 28px;
    }

    .product_finder_key_points_image {
      left: calc(50% - 120px);
      top: 50px;
      width: 240px;
    }
    .product_finder_ingredients_button {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
    .product_finder_ingredients_price_container {
      margin-top: 100px;
      margin-bottom: 16px;
    }

    .product_finder_ingredients_leaf {
      display: block;
      position: absolute;
      right: -40px;
      top: -40px;
      width: 123px;
      z-index: 1;
    }
    .product_finder_ingredients_man {
      display: block;
      position: absolute;
      z-index: 1;
      width: 95px;
      top: -85px;
      right: 80px;
    }
  }

  @media (min-width: 970px) {
    .product_finder_key_points_container,
    .product_finder_ingredients_container {
      max-width: 360px;
    }
    .product_finder_key_points_image {
      left: calc(50% - 160px);
      top: 50px;
      width: 300px;
    }
  }
`
