import isEmpty from 'lodash/isEmpty'
import {useCallback} from 'react'

import ArrowLeft from '../../../../../../assets/icons/ArrowLeftBlack.svg'
import ArrowRight from '../../../../../../assets/icons/ArrowRightBlack.svg'
import Responsive from '../../../../../HOC/Responsive'
import CustomCarousel from '../../../../../common/CustomCarousel'
import Typography from '../../../../../common/Typography/Typography'
import {StyledWrapper, TabsOuterContainer} from './Tabs.styles'

const Tabs = (props) => {
  const {isMobile = false} = props
  const {items = [], onTabChange = () => {}, activeCategory, themeToUse} = props

  const handleTabClick = useCallback(
    (event) => {
      const {name} = event.currentTarget.dataset
      if (name !== activeCategory) {
        onTabChange(name)
      }
    },
    [activeCategory, onTabChange]
  )

  if (isEmpty(items)) {
    return null
  }

  return (
    <StyledWrapper className="container">
      <div className="items">
        <CustomCarousel
          scrollDuration="100"
          iconLeft={ArrowLeft}
          iconRight={ArrowRight}
          renderSvgArrows={true}
        >
          {items.map((item, index) => {
            const {name = '', label, headingLevel = '', specialCategory} = item
            const isActive = name === activeCategory
            const headingTag = !!headingLevel ? `h${headingLevel}` : ''
            const categoryClass = isActive
              ? 'item category-Primary-btn'
              : 'item category-primary-bg2-btn'

            let categoryToConsider = name
            if (specialCategory) {
              categoryToConsider = specialCategory
            }
            return (
              <TabsOuterContainer
                className="item-container"
                key={name}
                as={headingTag}
              >
                <button
                  onClick={handleTabClick}
                  className={categoryClass}
                  key={name}
                  data-index={index}
                  data-name={name}
                  data-active={isActive}
                  data-category={themeToUse || categoryToConsider}
                >
                  <Typography text={label} variant="body-base-regular" />
                </button>
                {isActive && !isMobile && (
                  <div
                    data-category={themeToUse || categoryToConsider}
                    className="arrow-down"
                  ></div>
                )}
              </TabsOuterContainer>
            )
          })}
        </CustomCarousel>
      </div>
    </StyledWrapper>
  )
}

export default Responsive(Tabs)
