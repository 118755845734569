import styled from 'styled-components'

export const StyledCTA = styled.button`
  box-sizing: border-box;
  padding: 16px 56px;
  background-color: var(--brand-primary-black-color);
  display: inline-block;
  color: var(--brand-primary-white-color);
  font-family: var(--brand-font-family-text);
  font-weight: var(--brand-font-weight-medium);
  font-size: 16px;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;
  position: relative;
  overflow: hidden;
  -webkit-highlight: none;
  -webkit-tap-highlight-color: transparent;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    font-weight: var(--brand-font-weight-700);
    padding: 18px 95px;
    border-radius: 20px;
  }
`
export const StyledDirect = styled.a`
  box-sizing: border-box;
  padding: 16px 56px;
  background-color: var(--brand-primary-black-color);
  display: inline-block;
  color: var(--brand-primary-white-color);
  font-family: var(--brand-font-family-text);
  font-weight: var(--brand-font-weight-medium);
  font-size: 16px;
  border-radius: 16px;
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    font-weight: var(--brand-font-weight-700);
    padding: 18px 95px;
    border-radius: 20px;
  }
`
