import styled from 'styled-components'

export const TabsOuterContainer = styled.div`
  padding: 0;
  margin: 0;
  margin-right: 5px;

  &:last-child {
    margin-right: 12px;
  }

  @media (min-width: 768px) {
    margin-bottom: 20px;
    margin-right: 10px;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  .items {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    overflow: scroll;
    padding: 0 0 20px 16px;
  }
  .custom_carousel_wrapper_style {
    overflow: hidden;
  }

  .items::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  .arrow-down {
    width: 0;
    height: 0;
    margin: auto;
    margin-top: 6px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top: 14px solid var(--category-primary-color);
  }

  .item {
    position: relative;
    border-radius: 12px;
    border: none;
    min-width: 90px;
    padding: 12px 10px;
    outline: none;
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: var(--category-primary-bg2-color);
    color: var(--category-primary-color);
    user-select: none;
    overflow: hidden;
    -webkit-highlight: none;
    -webkit-tap-highlight-color: transparent;

    &[data-active='true'] {
      background-color: var(--category-primary-color);
      color: var(--brand-primary-white-color);
    }
  }

  .category-header {
    margin: 0 auto;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  @media (min-width: 768px) {
    width: 100%;
    display: flex;
    margin: auto;

    justify-content: center;
    max-width: 1120px;

    .items {
      max-width: 1170px;
      width: 100%;
      display: flex;
      flex-direction: row;
      overflow: scroll;
      padding: 0 0 20px 16px;
    }
    .custom_carousel_wrapper_style {
      max-width: 1170px;
      position: static;
      overflow: hidden;
    }
    .cust_car_icon {
      margin-top: -50px !important;
    }
    .cust_car_icon.left {
      left: var(--brand-tabs-carousel-left-icon-position) !important;
    }
    .cust_car_icon.right {
      right: -68px !important;
    }
    .cust_car_icon img {
      width: 60px;
      margin-top: 5px;
    }

    .item::-webkit-scrollbar {
      width: 0 !important;
    }

    .item {
      min-width: 150px;
      border-radius: 20px;
      border: none;
      padding: 18px 20px;
      outline: none;
      font-size: 20px;
      line-height: 23px;
    }
  }
`
